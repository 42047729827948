/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import { useReactiveVar, useLazyQuery } from '@apollo/client';

import slugify from 'slugify';
import LocalData from '../apollo/LocalData';
import { setInitialAddress } from '../apollo/LocalData/Operators/AddressOperator';
import { GET_BRANDS, GET_MY_ADDRESSES } from '../apollo/queries/home';
import { GET_PRODUCT } from '../apollo/queries/product';

import MenuProductCard from './menuProductCard';

function MenuProductBox({ data, brandsContext }) {
  const productList = data?.landingPage?.ProductList;
  const apiProducts = data?.allApiProduct?.nodes;

  const [userCoordinates, setUserCoordinates] = useState({ lat: 0, lng: 0 });
  const [campaignProduct, setCampaignProduct] = useState({});
  const addressLocalData = useReactiveVar(LocalData.selectedAddress);

  const [getMyAddresses] = useLazyQuery(GET_MY_ADDRESSES, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (value) => {
      const addresses = value?.addresses;
      setInitialAddress(addresses);
      getBrands();
    },
    onError: (error) => {
      console.log({
        message: 'Adresler çekilemedi' || error,
        type: 'danger',
        autoHide: true,
        icon: 'danger',
      });
    },
  });

  const [getBrands, { data: brands }] = useLazyQuery(GET_BRANDS, {
    variables: {
      lat: userCoordinates?.lat?.toString(),
      lng: userCoordinates?.lng?.toString(),
      addressId: addressLocalData?.addressID,
    },
    onCompleted: () => {
      LocalData.brands(brands?.brands);
    },
    onError: (error) => {
      console.log({
        message: 'Markalar çekilemedi' || error,
        type: 'danger',
        autoHide: true,
        icon: 'danger',
      });
    },
  });

  function getLocation(mounted) {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          if (mounted) {
            setUserCoordinates({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          }
        },
        () => {
          console.log('No location...');
        },
      );
    }
  }

  const [getProduct] = useLazyQuery(GET_PRODUCT, {
    onCompleted: (e) => {
      const [productBrandId, productId, restaurantId] = getCampaignProductDetail(campaignProduct);

      const hasProduct = e?.product;

      if (!hasProduct) {
        // Marka listeleme sayfasına git
        window.location.href = `/a/products/${productBrandId}/${restaurantId}`;
        return;
      }
      // Ürün detay sayfasına git
      window.location.href = `/a/product-detail/${productBrandId}/${restaurantId}/${productId}`;
    },
    onError: () => {
      console.log('Ürün içerik alınırken hata alındı.');
    },
  });

  useEffect(() => {
    getMyAddresses();
    getLocation();
  }, []);

  // Kampanya ürünü kişinin adresindeki markada var mı ?
  function isCampaignProductExist(product) {
    const [productBrandId, productId, restaurantId] = getCampaignProductDetail(product);

    getProduct({
      variables: {
        brandID: productBrandId,
        productID: productId,
        restaurantID: restaurantId,
      },
    });
  }

  // Kullanıcının adresinde kampanyanın olduğu marka var mı ?
  function isCampaignBrandExist(product) {
    const openBrandsIds = brands?.brands?.filter((brand) => brand?.isAvailable === true).map((brand) => brand?.value);

    const productBrandId = product?.brandCrmId;

    const isCampaignAccessible = openBrandsIds?.includes(productBrandId);
    return isCampaignAccessible;
  }

  function getCampaignProductDetail(product) {
    const productId = product?.crmId;
    const productBrandId = product?.brandCrmId;
    const productBrand = brands?.brands?.filter((brand) => brand?.value === productBrandId);
    const restaurantId = productBrand?.[0]?.restaurants?.[0]?.restaurantID;
    return [productBrandId, productId, restaurantId];
  }

  function handleOnPress(product) {
    if (!brands) {
      window?.dataLayer?.push({
        cd_pageType: 'detay sayfası',
      });
      window.location.href = `/${
        brandsContext.find((brand) => brand.node.CRMId === product?.Product?.BrandCrmId).node.NormalizedName
      }/${slugify(product?.Product?.Name.split('®').join(''), {
        lower: true,
        strict: true,
      })}`;
      return;
    }

    setCampaignProduct(product);

    if (!isCampaignBrandExist(product)) {
      window.location.href = '/a/';
      return;
    }

    isCampaignProductExist(product);
  }

  return (
    <>
      {productList?.map?.((item) => (
        <Col xs={12} sm={6} md={6} lg={4} className="no-padding" key={item?.Product?.CrmId}>
          <MenuProductCard
            brandsContext={brandsContext}
            data={{
              name: item?.Product?.Name,
              crmId: item?.Product?.CrmId,
              media: {
                path: item?.Product?.Image?.publicURL,
              },
              brandCrmId: item?.Product?.BrandCrmId,
              apiProducts,
            }}
            value={item?.Product?.CrmId}
            brandId={item?.Product?.BrandCrmId}
            onPress={() => handleOnPress(item)}
          />
        </Col>
      ))}
    </>
  );
}

MenuProductBox.propTypes = {
  data: PropTypes.object,
  brandsContext: PropTypes.array,
};

export default MenuProductBox;
