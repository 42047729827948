/* eslint-disable no-unused-expressions */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Slider from 'react-slick';
import { graphql } from 'gatsby';
import dayjs from 'dayjs';
import youtubeRegex from '../utils/youtubeRegex';
import { DateFormats } from '../utils/dateUtils';
import Layout from '../components/layout';
import ProductCard from '../components/productCard';
import ShareButtons from '../components/shareButtons';
import SEO from '../components/seo';
import { useWindowSize } from '../utils/windowSize';
import MenuProductBox from '../components/menuProductBox';
import PageTitle from '../components/pageTitle';
import YoutubePlayer from '../components/Video/Youtube';

export const query = graphql`
  query LandingPage($slug: String) {
    landingPage: strapiLandingPage(Slug: { eq: $slug }) {
      Slug
      Banner {
        url
        alternativeText
      }
      PageType
      DisplayOrder
      Title
      SEOTitle
      SEODescription
      TargetSlider
      Brand {
        Name
        Splash {
          publicURL: url
        }
        Logo {
          publicURL: url
        }
      }
      StartDate
      EndDate
      DisplayStartTime
      DisplayEndTime
      DetailText
      ProductList {
        ShortText
        ProductImage {
          publicURL: url
          alternativeText
        }
        Product {
          Name
          CrmId
          Image {
            publicURL: url
            alternativeText
          }
          BrandCrmId
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
        social {
          facebook
          twitter
          whatsapp
        }
      }
    }
    allApiProduct {
      nodes {
        brandId
        products {
          subcategories {
            products {
              value
              price
              name
            }
          }
        }
      }
    }
  }
`;

const LandingPage = ({ location, data, pageContext }) => {
  const landingPageData = data?.landingPage;
  const isMultipleCampaign = landingPageData?.ProductList?.length > 1;
  const productData = landingPageData?.ProductList?.[0];
  const brandName = landingPageData?.Brand?.Name;
  const windowSize = useWindowSize();
  const youtubeIDDetailText = youtubeRegex(landingPageData?.DetailText);

  useEffect(() => {
    window?.dataLayer?.push({
      cd_pageType: 'detay sayfası',
    });
  }, []);

  // Site Meta Data
  const siteTitle = data?.site?.siteMetadata?.title;
  const url = data?.site?.siteMetadata?.siteUrl;
  // SEO
  const title = landingPageData?.SEOTitle;
  const description = landingPageData?.SEODescription;
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: location.pathname,
    name: title,
    description,
  };

  function onClickButton(landingPage) {
    switch (landingPage.Brand?.Name) {
      case 'Burger King®':
        window.location.href = '/burger-king';
        break;
      case 'Popeyes®':
        window.location.href = '/popeyes';
        break;
      case "Arby's®":
        window.location.href = '/arbys';
        break;
      case 'Usta Dönerci®':
        window.location.href = '/usta-donerci';
        break;
      case 'Usta Pideci®':
        window.location.href = '/usta-pideci';
        break;
      case 'AMASYA ET ÜRÜNLERİ':
        window.location.href = '/amasya-et-urunleri';
        break;
      default:
    }
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: '50',
  };

  function CampaignDetails() {
    if (!landingPageData.DetailText) return null;
    return (
      <div className="content-box-small">
        <p className="content-title">Kampanya Bilgileri</p>
        {youtubeIDDetailText && <YoutubePlayer youtubeID={youtubeIDDetailText} />}
        <div dangerouslySetInnerHTML={{ __html: landingPageData.DetailText }}></div>
      </div>
    );
  }

  if (landingPageData?.PageType === 'Image') {
    return (
      <Layout sidebar={false} brandName={brandName}>
        <SEO title={title} schemaMarkup={schema} />
        <div className="full-page-img-wrapper white-wrapper">
          <div>
            <img src={productData?.ProductImage?.publicURL} alt={landingPageData?.Title} width="100%" height="auto" />
          </div>
        </div>
      </Layout>
    );
  }
  return (
    <Layout
      title="Tıkla Gelsin"
      sidebar={false}
      brandName={brandName || landingPageData?.Title}
      location={location}
      hasProduct={!isMultipleCampaign && !!data?.landingPage?.ProductList[0]?.Product}
    >
      <SEO title={title} description={description} siteUrl={location?.pathname} schemaMarkup={schema} />
      {!isMultipleCampaign && (
        <Row className={`campaign-image-container white-wrapper ${windowSize.width > 992 ? '' : 'mobile-view'}`}>
          <Col lg={6} className="no-padding">
            <img
              src={productData?.ProductImage?.publicURL}
              alt={productData?.Product?.Name}
              width="100%"
              height="auto"
              style={{ borderRadius: '15px' }}
            />
            {windowSize.width > 992 ? (
              <div className="img-footer-container">
                <div className="expiry-date text-left">
                  <div className="expiry-text">Kampanya geçerlilik tarihi:</div>
                  <div>
                    {`${dayjs(data?.landingPage?.StartDate).format(DateFormats.DAY_MONTH_YEAR)} - ${dayjs(
                      data?.landingPage?.EndDate,
                    ).format(DateFormats.DAY_MONTH_YEAR)}`}
                  </div>
                </div>
                <ShareButtons
                  socialConfig={{
                    config: {
                      url: `${url}/${landingPageData?.Slug}`,
                      title: siteTitle,
                    },
                  }}
                />
              </div>
            ) : null}
          </Col>
          <Col lg={6} className="no-padding">
            <ProductCard data={data} single />
          </Col>
        </Row>
      )}
      {isMultipleCampaign && (
        <Row className="multiple-campaign-container">
          {landingPageData?.Banner?.length > 0 && (
            <Col lg={12} className="no-padding">
              <Slider className="carousel-custom" {...settings}>
                {[...landingPageData?.Banner]?.map((item, index) => (
                  <div key={`${item?.node?.Slug}-${index + 1}`}>
                    <img
                      alt={item.alternativeText}
                      className="d-block w-100"
                      width="100%"
                      height="auto"
                      src={item?.url}
                    />
                  </div>
                ))}
              </Slider>
            </Col>
          )}
          <Col lg={12} className="no-padding">
            <PageTitle>
              <h2 className="text">{landingPageData?.Title}</h2>
            </PageTitle>

            <Row style={{ padding: '24px', margin: 0 }} className="products-tab">
              <MenuProductBox data={data} brandsContext={pageContext.brandsContext} />
            </Row>
            <div className="img-footer-container">
              {landingPageData?.Brand?.Logo?.publicURL ? (
                // eslint-disable-next-line react/button-has-type
                <button className="brand-button" onClick={() => onClickButton(landingPageData)}>
                  <img
                    src={landingPageData?.Brand?.Logo?.publicURL}
                    className="brand-logo"
                    width="48"
                    height="auto"
                    alt={landingPageData?.Brand?.Name}
                  />
                  <div className="brand-link-text-container">
                    <span className="brand-link-text">
                      {landingPageData?.Brand?.Name}
                      <br></br>
                      Restoran Sayfası
                    </span>
                    <i className="icon-ArrowRight"></i>
                  </div>
                </button>
              ) : null}
              <div className="expiry-date">
                <div className="bold">Kampanya geçerlilik tarihi</div>
                <div>
                  {`${dayjs(data?.landingPage?.StartDate).format(DateFormats.DAY_MONTH_YEAR)} - ${dayjs(
                    data?.landingPage?.EndDate,
                  ).format(DateFormats.DAY_MONTH_YEAR)}`}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {windowSize.width > 992 && !isMultipleCampaign ? null : <CampaignDetails />}
    </Layout>
  );
};

LandingPage.propTypes = {
  pageContext: PropTypes.any,
  location: PropTypes.object,
  data: PropTypes.object,
};

export default LandingPage;
