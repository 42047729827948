import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

function YoutubePlayer({ youtubeID }) {
  return (
    <div className="player-container">
      <iframe
        src={`https://www.youtube.com/embed/${youtubeID}`}
        title="Tıkla Para"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowFullScreen
        width="100%"
        height="315"
      />
    </div>
  );
}

YoutubePlayer.propTypes = {
  youtubeID: PropTypes.string,
};

export default YoutubePlayer;
