import { gql } from '@apollo/client';

export const GET_PRODUCT = gql`
  query GET_PRODUCT($productID: String, $restaurantID: String, $brandID: String) {
    product(brandID: $brandID, productID: $productID, restaurantID: $restaurantID) {
      product {
        name
        description
        price
        isAvailable
        packageGram
        productContent
        isChangeablePrice
        storageConditions
        hasOptions
        descriptionList {
          Description
          Icon {
            Icon
          }
        }
        productImage {
          Media {
            Url
          }
          Template {
            NumberOfSection
            Right
          }
        }
        brand {
          name
          val
        }
        media {
          path
        }
      }
      options {
        name
        id
        parentGroupName
        UniqueId
        quantity
        price
        maximumOptioncount
        title
        productOptionTypeId
        showLargerSelection
        controlType
        items {
          id
          productId
          title
          firstChoice
          minQuantity
          maxQuantity
          pricePerProductOption
          bigSizeName
          normalSizeName
          isLargeMenu
          standardQuantity
          childOptions {
            name
            id
            quantity
            price
            maximumOptioncount
            title
            productOptionTypeId
            optionTypeid
            parentGroupName
            controlType
            items {
              id
              parentGroupName
              productId
              title
              firstChoice
              minQuantity
              maxQuantity
              pricePerProductOption
              bigSizeName
              normalSizeName
              isLargeMenu
              standardQuantity
            }
          }
        }
      }
    }
  }
`;
