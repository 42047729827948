import React from 'react';
import PropTypes from 'prop-types';

function PageTitle(props) {
  return (
    <div className={`page-title ${props.size} ${props.className}`} style={props.style}>
      <div className="title-row">
        <span className="text">{props.children}</span>
      </div>
      <div className="short-line" />
      <div className="long-line" />
    </div>
  );
}

PageTitle.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object.isRequired, PropTypes.string.isRequired]),
  size: PropTypes.string, // sm
  style: PropTypes.object,
  className: PropTypes.string,
};

export default PageTitle;
