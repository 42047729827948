/* eslint-disable no-console */
/* eslint-disable no-plusplus */
import _ from 'lodash';
import LocalData from '../index';

export function getSelected(list = []) {
  for (let index = 0; index < list.length; index++) {
    if (list[index].isSelected) return list[index];
  }
  return false;
}

export function setInitialAddress(addressList = []) {
  // burada kullanıcının ilk kez adresini set ediyoruz. Home screendeki servis çağrısına bağlı
  // selected adres varsa set etmeiyoruz. Çünkü server tarafında selectedadress statei yok
  if (_.isEmpty(addressList)) return;
  const toBeSelected = getSelected(addressList);

  if (toBeSelected) {
    LocalData.selectedAddress({
      addressID: toBeSelected.addressID,
      address: toBeSelected,
      addressList,
    });
  }
}

export function updateSelectedAddress(address, addressList) {
  LocalData.selectedAddress({
    addressID: address.addressID,
    address,
    addressList: updatedAddressList(address, addressList),
  });
}

export function updatedAddressList(address, addressList) {
  const updatedList = addressList;

  for (let index = 0; index < addressList.length; index++) {
    if (addressList[index].addressID === address.addressID) {
      updatedList[index].isSelected = true;
    } else if (addressList[index].addressID !== address.addressID && addressList[index].isSelected === true) {
      updatedList[index].isSelected = false;
    }
  }

  return updatedList;
}

export default {
  getSelected,
  setInitialAddress,
  updateSelectedAddress,
  updatedAddressList,
};
