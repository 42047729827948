/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Tabs, Tab } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';
import youtubeRegex from '../utils/youtubeRegex';
import CurrencyText from './currencyText';
import { useWindowSize } from '../utils/windowSize';
import ShareButtons from './shareButtons';
import { DateFormats } from '../utils/dateUtils';
import YoutubePlayer from './Video/Youtube';
import Link from './Link';

const ProductCard = ({ data, single }) => {
  const windowSize = useWindowSize();
  const landingPageData = data?.landingPage;
  const brandId = landingPageData?.ProductList[0]?.Product?.BrandCrmId;
  const productsByBrand = data?.allApiProduct?.nodes?.filter((brand) => brand?.brandId === brandId)?.[0]?.products;

  let productPrice = null;
  function getProductPrice(productId) {
    for (let i = 0; i < productsByBrand?.length; i += 1) {
      const subcategories = productsByBrand?.[i]?.subcategories;
      for (let k = 0; k < subcategories?.length; k += 1) {
        const products = subcategories?.[k]?.products;
        productPrice = products?.find((item) => item?.value === productId)?.price;
        if (productPrice !== undefined) return productPrice;
      }
    }
    return null;
  }

  if (single) {
    const productData = landingPageData?.ProductList[0];
    const hasProduct = !!landingPageData?.ProductList[0]?.Product;
    const productId = productData?.Product?.CrmId;
    // Site Meta Data
    const siteTitle = data?.site?.siteMetadata?.title;
    const url = data?.site?.siteMetadata?.siteUrl;
    const hasCampaignContent = !!productData?.ShortText;
    const hasCampaignInfo = !!landingPageData.DetailText;
    const isMultipleTab = hasCampaignContent && hasCampaignInfo;

    const youtubeIDShortText = youtubeRegex(productData?.ShortText);
    const youtubeIDDetailText = youtubeRegex(landingPageData?.DetailText);

    const campaignContent = (
      <>
        {productData?.ShortText && (
          <>
            {youtubeIDShortText && <YoutubePlayer youtubeID={youtubeIDShortText} />}
            <div dangerouslySetInnerHTML={{ __html: productData?.ShortText }}></div>
          </>
        )}
      </>
    );

    const campaignInfo = (
      <>
        {landingPageData?.DetailText ? (
          <div>
            {youtubeIDDetailText && <YoutubePlayer youtubeID={youtubeIDDetailText} />}
            <div dangerouslySetInnerHTML={{ __html: landingPageData?.DetailText }}></div>
          </div>
        ) : null}
      </>
    );

    return (
      <div className="product-card mt-4">
        <div className="top-content">
          <div className="title">{productData?.Product?.Name}</div>
          <div className="price-container">
            <CurrencyText className="prev-price">{productData?.Product?.PreviousPrice}</CurrencyText>
            <CurrencyText className="price" enableTheme>
              {getProductPrice(productId)}
            </CurrencyText>
          </div>
        </div>
        {hasProduct ? (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <div className="add-to-cart-segment ">
            <a
              // eslint-disable-next-line no-return-assign
              onClick={() => (window.location.href = `/a/cproduct?brandId=${brandId}&productId=${productId}`)}
              className="btn btn-primary btn-add btn-block mb-3"
              aria-hidden="true"
            >
              Sepete Ekle
            </a>
          </div>
        ) : null}

        {windowSize.width > 992 ? (
          <>
            {isMultipleTab ? (
              <Tabs defaultActiveKey="campaignContent" id="uncontrolled-tab" className="campaign-tabs">
                <Tab eventKey="campaignContent" title="Kampanya İçeriği" className="campaign-tab-content">
                  {campaignContent}
                </Tab>
                <Tab eventKey="campaignInfo" title="Kampanya Bilgileri" className="campaign-tab-content">
                  {campaignInfo}
                </Tab>
              </Tabs>
            ) : (
              <>
                {hasCampaignContent && (
                  <div className="campaign-tab">
                    <a className="nav-item nav-link">Kampanya İçeriği</a>
                    <div className="box-area">{campaignContent}</div>
                  </div>
                )}

                {hasCampaignInfo && (
                  <div className="campaign-tab">
                    <a className="nav-item nav-link">Kampanya Bilgileri</a>
                    <div className="box-area">{campaignInfo}</div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div>
            {campaignContent}
            <div className="img-footer-container">
              <ShareButtons
                socialConfig={{
                  config: {
                    url: `${url}/${landingPageData?.Slug}`,
                    title: siteTitle,
                  },
                }}
              />
              <div className="expiry-date">
                <div className="expiry-text">Kampanya geçerlilik tarihi:</div>
                <div>
                  {`${dayjs(data?.landingPage?.StartDate).format(DateFormats.DAY_MONTH_YEAR)} - ${dayjs(
                    data?.landingPage?.EndDate,
                  ).format(DateFormats.DAY_MONTH_YEAR)}`}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  const product = data;
  return (
    <div className="product-card">
      <Row>
        {product?.Product?.Image?.publicURL && (
          <Col lg={4}>
            <img src={product?.Product?.Image?.publicURL} alt={product?.Product?.Name} width="100%" height="auto" />
          </Col>
        )}
        {product?.Product?.Name && (
          <Col lg={8}>
            <div className="top-content">
              <div className="title">{product?.Product?.Name}</div>
            </div>
          </Col>
        )}
      </Row>
      {product?.ShortText ? <ReactMarkdown source={product?.ShortText} /> : null}
      <Link to="/a/" className="btn btn-primary btn-add btn-block">
        <i className="icon-Add" />
      </Link>
    </div>
  );
};

ProductCard.propTypes = {
  data: PropTypes.object,
  single: PropTypes.bool,
};

export default ProductCard;
